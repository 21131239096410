import { useCriteriaVM } from "presentation/hook/Criteria/useCriteriaVM";
import { useCriteriaTracked } from "presentation/store/Criteria/CriteriaProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import CriteriaComponentPanel from "./Form/CriteriaComponentPanel";
import CriteriaComponentTablePanel from "./Table/CriteriaComponentTablePanel";

export const CriteriaEditPanel: React.FC = () => {
    const [criteriaState] = useCriteriaTracked();
    const criteriaVM = useCriteriaVM();
    const { isSliderOpen } = criteriaState.componentState;
    const criterEty = criteriaState.currentSelectedRow;
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        criterEty?.id && criteriaVM.onSearchComponent(criterEty.id)
        setIsLoading(false);
    }, [criterEty.id, criteriaVM, criterEty?.triggeredBy, criteriaState.entrypoint])
    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
        return <div className='main-comp-wrapper'>
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={isSliderOpen?'34%':'100%'}
                    rightSectionWidth={isSliderOpen?'66%':'0%'}
                    draggable={true}
                    leftChildren={<CriteriaComponentTablePanel/>}
                    rightChildren={<CriteriaComponentPanel/>} />
            </div>
    }
    
export default memo(CriteriaEditPanel);