import { SelectionChangedEvent } from "ag-grid-community";
import { ConstraintGroupEntity } from "domain/entity/Criteria/ConstraintGroupEntity";
import { transferRowData } from "presentation/constant/Criteria/ChargeCriteriaColumnDefinition";
import { INITIAL_CRITERIA_COMP_COL_DEF } from "presentation/constant/Criteria/CriteriaComponentColumnDefinition";
import { CriteriaConstant } from "presentation/constant/Criteria/CriteriaConstant";
import { comboBoxTriggeredByIndOption } from "presentation/constant/CriteriaDropdownProps/CriteriaDropdownPropsOptions";
import { useCriteriaVM } from "presentation/hook/Criteria/useCriteriaVM";
import { useCriteriaTracked } from "presentation/store/Criteria/CriteriaProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldType, HPHCheckbox, HPHTable, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import CriteriaEditPanelTitleBar from "../CriteriaEditPanelTitleBar";

const CriteriaComponentTablePanel:React.FC = () => {
    const [criteriaState] = useCriteriaTracked();;
    const criteriaVM = useCriteriaVM();
    const {consGrpList} = criteriaState.componentState;
    const {isAdd, isEditable, isRead, editingCriteriaEntity, isSaveClicked, allFormState} = criteriaState.masterState;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const {currentSelectedRow, entrypoint} = criteriaState;
    const currentEntity = isRead? currentSelectedRow : editingCriteriaEntity;
    
    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_CRITERIA_COMP_COL_DEF.slice());
            
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const memoSubChgTypeOptions = useMemo(() => {
        const commonSubChgTypeOptions = criteriaState.dynamicOptions.subChgTypeDropdownOptions[currentEntity?.chargeType!==null?currentEntity?.chargeType:0]
        return commonSubChgTypeOptions
    },[currentEntity.chargeType, criteriaState.dynamicOptions.subChgTypeDropdownOptions])
    

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        criteriaVM.updateSelectedComponentRows(selectedRows);
      }, [criteriaVM])

    const handleRowDoubleClick = useCallback((consGrpComp: ConstraintGroupEntity) => {
        criteriaVM.onComponentRowClick(consGrpComp);
    }, [criteriaVM])


    const handleAddClick = useCallback(async() => {
        criteriaVM.onComponentAdd();      
    },[criteriaVM])

    const memoCriteriaComponentTable = useMemo(() => {
        return <HPHTable
            id='CriteriaComponent-table'
            headerLabel={CriteriaConstant.Table.GROUP_LIST}            
            columns={INITIAL_CRITERIA_COMP_COL_DEF.slice()}
            data={transferRowData(consGrpList??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={!isRead}
            showDeleteButton={false}
            showReloadIcon={false}
            isScrollHighlighted={true}
            selectionMode={false}
            //rowSelection={"multiple"}
            onAddClick={handleAddClick}
            onSelectionChanged={handleSelectionChange}
            onRowDoubleClick={(e: any) => handleRowDoubleClick(e.data)}
            gridHeight="customHeight"
            customHeight={entrypoint === 'GCGEN' ? "calc(43vh)" :((entrypoint === 'CHGEXL' || entrypoint === 'DOCSEP' || entrypoint === 'DOCGROUP') ? "calc(52vh)" :"calc(46vh)")}
            ref={gridRef} />
    }, [consGrpList, handleAddClick, handleSelectionChange, entrypoint, handleRowDoubleClick, isRead])

    const memoEditPanelTitleBar = useMemo(() => {
        return <CriteriaEditPanelTitleBar/>
    }, [])

    const memoCriteriaName = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"400px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.criteriaName || ''}
                fieldValue={currentEntity?.criteriaName}
                fieldLabel={CriteriaConstant.Table.CRITERIA_NAME}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'criteriaName'}
                isShowMissingError={true}
                allValues={currentEntity?.criteriaName}
                maxLength={50}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
        </div>
    , [currentEntity?.criteriaName, isSaveClicked, isRead, criteriaVM, allFormState]) 
    
    const memoCriteriaDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"615px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.criteriaDesc?.toString() || ''}
                fieldValue={currentEntity?.criteriaDesc?.toString()}
                fieldLabel={CriteriaConstant.Table.CRITERIA_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'criteriaDesc'}
                isShowMissingError={false}
                allValues={currentEntity?.criteriaDesc?.toString()}
                maxLength={150}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
        </div>
    , [currentEntity?.criteriaDesc, isSaveClicked, isRead, criteriaVM, allFormState]) 

    const memoActiveInd = useMemo(() =>
        <>
          <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={CriteriaConstant.Table.ACTIVE_IND} 
                fieldType={FieldType.TEXT} 
                isReadOnly={true}
                onFieldChange={()=>{}}/>
            <HPHCheckbox
                label={""}
                checked={currentEntity?.activeInd==='Y'}
                disabled={isRead}
                onChange={(e) => criteriaVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        </>
    , [currentEntity?.activeInd, isRead, criteriaVM])

    const memoChargeInd = useMemo(() =>
          <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.chargeInd || ''}
                fieldValue={currentEntity?.chargeInd?.toString()}
                fieldLabel={CriteriaConstant.Table.CHARGE_IND}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeInd'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={[]}
                options={criteriaState.dynamicOptions.chargeIndDropdownOptions}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [currentEntity?.chargeInd, isRead, criteriaVM, isSaveClicked, criteriaState.dynamicOptions.chargeIndDropdownOptions, allFormState])

    const memoEffectiveDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom:"24px"}}>
            <DatePickerComponent
                    disabled={isRead}
                    label={CriteriaConstant.Table.EFFECTIVE_DATE}
                    width="185px"
                    date={currentEntity?.effectiveDate??''}
                    fieldName="effectiveDate" 
                    errorMessage={allFormState?.effectiveDate}
                    onDateChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any, objName?: any) => 
                        criteriaVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue, FieldType.DATE_TIME)}/>
        </div>
    , [currentEntity?.effectiveDate, criteriaVM, isRead, allFormState])

    const memoExpiryDate = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom:"24px"}}>
            <DatePickerComponent
                    disabled={isRead}
                    label={CriteriaConstant.Table.EXPIRY_DATE}
                    width="185px"
                    date={currentEntity?.expiryDate??''}
                    fieldName="expiryDate"      
                    errorMessage={allFormState?.expiryDate}
                    optional = {true}
                    onDateChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any, objName?: any) => 
                        criteriaVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue, FieldType.DATE_TIME)}/>
        </div>
    , [currentEntity?.expiryDate, criteriaVM, isRead, allFormState])

    const memoPriority = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"185px", marginBottom:"24px"}}>            
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEntity?.priority?.toString() || ''}
                fieldValue={currentEntity?.priority?.toString()}
                fieldLabel={CriteriaConstant.Table.PRIORITY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'priority'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
        </div>
    , [currentEntity?.priority, isSaveClicked, criteriaVM]) 

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px"}}>
          <NbisCommonField
              errorMessages={allFormState}
              isReadOnly={isRead}
              isShowOptional={false}
              readOnlyValue={currentEntity?.chargeType || ''}
              fieldValue={currentEntity?.chargeType?.toString()}
              fieldLabel={CriteriaConstant.Table.CHARGE_TYPE}
              isSaveClicked={isSaveClicked}
              fieldType={FieldType.DROPDOWN}
              isShowMissingError={true}
              fieldKey={'chargeType'}
              maxLength={60}
              requiredFieldList={[]}
              options={criteriaState.dynamicOptions.chgTypeDropdownOptions}
              onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                  criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [currentEntity?.chargeType, isRead, criteriaVM, criteriaState.dynamicOptions.chgTypeDropdownOptions, isSaveClicked, allFormState])

  const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
          <NbisCommonField
            errorMessages={{}}
            isReadOnly={isRead}
            isShowOptional={true}
            readOnlyValue={currentEntity?.subChargeType || ''}
            fieldValue={currentEntity?.subChargeType?.toString()}
            fieldLabel={CriteriaConstant.Table.SUB_CHARGE_TYPE}
            isSaveClicked={isSaveClicked}
            fieldType={FieldType.DROPDOWN}
            fieldKey={'subChargeType'}
            maxLength={60}
            requiredFieldList={[]}
            options={memoSubChgTypeOptions}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [currentEntity?.subChargeType, isRead, criteriaVM, isSaveClicked, memoSubChgTypeOptions])

    const memoTriggeredBy = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "185px", marginBottom: "24px" }}>
        <NbisCommonField
            errorMessages={allFormState}
            isReadOnly={isRead}
            isShowOptional={false}
            readOnlyValue={currentEntity?.triggeredBy || ''}
            fieldValue={currentEntity?.triggeredBy?.toString()}
            fieldLabel={CriteriaConstant.Table.TRIGGERED_BY}
            isSaveClicked={isSaveClicked}
            fieldType={FieldType.DROPDOWN}
            fieldKey={'triggeredBy'}
            isShowMissingError={true}
            maxLength={60}
            requiredFieldList={[]}
            options={comboBoxTriggeredByIndOption}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                criteriaVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [currentEntity?.triggeredBy, isRead, criteriaVM, isSaveClicked, allFormState])
    
    let style = {
        height: entrypoint === 'GCGEN' ? '43vh' : ((entrypoint === 'CHGEXL' || entrypoint === 'DOCSEP' || entrypoint === 'DOCGROUP') ? '34vh' : '40vh'), 
        maxHeight: entrypoint === 'GCGEN' ? '43vh': ((entrypoint === 'CHGEXL' || entrypoint === 'DOCSEP' || entrypoint === 'DOCGROUP') ? '34vh' : '40vh'), 
    };
    /*if(entrypoint === 'GCGEN'){
        style =  {
            height: 'calc(100vh - 570px)', 
            maxHeight: 'calc(100vh - 570px)', 
            overflow: 'auto' 
        };
    }else{
        style =  {
            height: 'calc(100vh - 650px)', 
            maxHeight: 'calc(100vh - 650px)', 
            overflow: 'auto' 
        };
    }*/

    return <>
    <StyledSidebar style={{ width: '100%', height:"99vh", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {memoEditPanelTitleBar}

        <div className={'add-edit-form'} style={style}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            <SidebarTitle>{isAdd ? "NEW Criteria" : currentEntity?.criteriaName}</SidebarTitle>

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>
                    <CriteriaItemContainer>
                        {!isAdd && memoPriority}
                        {memoCriteriaName}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {memoCriteriaDesc}
                    </CriteriaItemContainer>
                    {entrypoint === 'GCGEN' &&
                        <CriteriaItemContainer>
                            {memoChargeType}
                            {memoSubChargeType}
                            {memoTriggeredBy}
                        </CriteriaItemContainer>
                    }
                    <CriteriaItemContainer>
                        {memoEffectiveDate}
                        {memoExpiryDate}
                        {entrypoint === 'CHARGE' && memoChargeInd}
                        {entrypoint !== 'CHARGE' && memoActiveInd}
                    </CriteriaItemContainer>
                    <CriteriaItemContainer>
                        {entrypoint === 'CHARGE' && memoActiveInd}
                    </CriteriaItemContainer>
                </div>
            </div>
        </div>    
        <div style={{width: '66vh'}}>
            { <TableWrapper >{memoCriteriaComponentTable}</TableWrapper>}
        </div>
    </StyledSidebar>
    </>;
}

export default memo(CriteriaComponentTablePanel);