export const CriteriaConstant = {
    Charge:{
        CHARGE: "Charge",
    },
    Title: {
        CHARGE_CRITERIA_MAIN: "Charge Criteria",
        GENERAL_CHARGE_CRITERIA_MAIN: "General Charge Generation Criteria",
        CHARGE_EXCLUSION_CRITERIA_MAIN: "Charge Exclusion Criteria",
        DOCUMENT_SEPARATION_CRITERIA_MAIN: "Document Separation Criteria",
        DOCUMENT_GROUPING_CRITERIA_MAIN: "Document Grouping Criteria",
    },

    Table: {
        GROUP_LIST: "GROUP LIST",
        CRITERIA_NAME: "Name",
        CRITERIA_DESC: "Description",
        CHARGE_IND: "Charge Ind.",
        EFFECTIVE_DATE: "Effective Date",
        EXPIRY_DATE: "Expiry Date",
        ACTIVE_IND: "Active Ind.",
        CHARGE_TYPE: "Charge Type",
        SUB_CHARGE_TYPE: "Sub Charge Type",
        TRIGGERED_BY: "Triggered By",
        PRIORITY: "Priority",

        NAME: "Name",
        TYPE: "Type",

        GROUP_NAME: "Group Name",
        CRITERIA: "Criteria",
    },

    Search: {
        
    }


}

export const tariffCodeMainRequiredFieldList: string[] = [
    'tariffType',
    'tariffCode',
    'tariffCodeDesc',
    'tariffSeqOne',
    'tariffSeqTwo',
    'tariffSeqThree',
    'activeInd',
];

export const tariffCodeCSVColumnMapping: string[] = [
    'tariffType',
    'tariffCode',
    'parentTariffType',
    'parentTariffCode',
    'tarSchemeCode',
    'tarNature',
    'percentage',
    'tariffCodeDescOther',
    'includeTputInd',
    'activeInd',
];

export const tariffCodePositiveIntegerNumberFieldList: string[] = [
    'tariffSeq1',  
    'tariffSeq2',  
    'tariffSeq3',
];

