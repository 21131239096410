import { ConstraintEntity } from "domain/entity/Criteria/ConstraintEntity";
import { ConstraintValueEntity } from "domain/entity/Criteria/ConstraintValueEntity";
import _ from "lodash";
import { comboBoxEvaluatorForStringOption, comboBoxEvaluatorOption } from "presentation/constant/CriteriaDropdownProps/CriteriaDropdownPropsOptions";
import { useCriteriaVM } from "presentation/hook/Criteria/useCriteriaVM";
import { CriteriaModel } from "presentation/model/Criteria/CriteriaModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { memo, useCallback, useEffect, useState } from "react";
import { FieldType, IconButton, IFieldValue } from "veronica-ui-component/dist/component/core";

const ConstraintItemPanel = ({
    key,
    constraintItem, 
    criteriaState,
    onDeleteConstraintItem,
    onUpdateConstraintItem
}: {
    key: number
    constraintItem: ConstraintEntity, 
    criteriaState: CriteriaModel,
    onDeleteConstraintItem: (removeItem: ConstraintEntity) => void
    onUpdateConstraintItem: (updateItem: ConstraintEntity) => void
}) => {
    const [currentConstraintDef, setCurrentConstraintDef] = useState(constraintItem.constraintDef);
    const [currentConstraintEvaluator, setCurrentConstraintEvaluator] = useState(constraintItem.constraintEvaluator);
    const [constraintValues, setConstraintValues] = useState(constraintItem.constraintValues);
    const { allFormState, isRead, isSaveClicked, currentSelectItem } = criteriaState.componentState;
    const [comboBoxValueOption, setComboBoxValueOption] = useState<DropdownProps[]>();
    const [comboBoxDefOption, setComboBoxDefOption] = useState<DropdownProps[]>();
    const [isMultipleValue, setIsMultipleValue] = useState(true);
    const criteriaVM = useCriteriaVM();

    useEffect(() => {
        const fetchValueData = async () => {
            if (constraintItem.constraintDef?.constraintDefDisplayName) {
                const valueData = await criteriaVM.getConsGroupDefValue(constraintItem.constraintDef.constraintDefDisplayName + ':' + constraintItem.constraintDef.constraintDefName);
                setComboBoxValueOption(valueData);
            }
        };
        fetchValueData();
    }, [constraintItem.constraintDef, criteriaState.dynamicOptions.consValueDropdownOptions, criteriaVM]);

    useEffect(() => {
        if (currentSelectItem.constraintGroupDefName) {
            setComboBoxDefOption(criteriaState.dynamicOptions.consDefDropdownOptions[currentSelectItem.constraintGroupDefName]);
        }else{
            const newOptions = criteriaState.consGrpsTypeDropdownOptions[0];
            setComboBoxDefOption(criteriaState.dynamicOptions.consDefDropdownOptions[newOptions?.key ?? '']);
        }
    }, [currentSelectItem.constraintGroupDefName, criteriaState.dynamicOptions.consDefDropdownOptions, criteriaState.consGrpsTypeDropdownOptions, comboBoxDefOption]);

    const onConstraintDefChange = useCallback(async (fieldKey: string, fieldValue: IFieldValue) => {
        const fieldValueStr = fieldValue?.toString();
        const selectedDef = criteriaState.allConstraintDef.find((def) => def.constraintDefDisplayName === fieldValueStr);

        if (selectedDef) {
            setCurrentConstraintDef(selectedDef);
            let consValueList: ConstraintValueEntity[] = [];
            setConstraintValues(consValueList);
            onUpdateConstraintItem({...constraintItem, constraintDef: selectedDef, constraintDefId: selectedDef.id, constraintValues: consValueList})
            //criteriaVM.getConsGroupDefValue(selectedDef.constraintDefDisplayName+':'+selectedDef.constraintDefName);
        }
    }, [constraintItem, criteriaState.allConstraintDef, onUpdateConstraintItem]);

    const onConstraintEvaluatorChange = useCallback(async (fieldKey: string, fieldValue: IFieldValue) => {
        const fieldValueStr = fieldValue?.toString() ?? null;
        let constraintValues : ConstraintValueEntity[] = constraintItem.constraintValues;
        setCurrentConstraintEvaluator(fieldValueStr);
        if(fieldValueStr === "IN" || fieldValueStr === "NOT_IN"){
            setIsMultipleValue(true);
            constraintValues = constraintItem.constraintValues.filter(item => item.constraintValue !== "");
        }else{
            setIsMultipleValue(false);
        }
        setConstraintValues(constraintValues);
        onUpdateConstraintItem({...constraintItem, constraintEvaluator: fieldValueStr, constraintValues: constraintValues})
    }, [constraintItem, onUpdateConstraintItem]);

    const onConstraintValuesChange = useCallback(async (fieldName: string, fieldValue: IFieldValue) => {
        let val = fieldValue;
        let consValueList: ConstraintValueEntity[] = [];
        if(val){
            if (_.isArray(val)) {
                val = _.uniq(val?.map((item: any) => item.value || item.key));

                consValueList= val.map(value => ({
                        id: null,
                        constraintId: constraintItem.id,
                        constraintValue: value
                    }));
            } else {
                consValueList = [{
                    id: null,
                    constraintId: constraintItem.id,
                    constraintValue: val?.toString() ?? ""
                }]
            }
        }else{
            consValueList = []
        }

        setConstraintValues(consValueList);
        onUpdateConstraintItem({...constraintItem, constraintValues: consValueList})
    }, [onUpdateConstraintItem, constraintItem]);

    const memoCriteria =
        <div className='im-flex-row-item' style={{ width: "20vw" }}>
            <NbisCommonField
                errorMessages={allFormState}                
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentConstraintDef?.constraintDefDisplayName || ''}
                fieldValue={currentConstraintDef?.constraintDefDisplayName}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'constraintDefDisplayName'}
                options={comboBoxDefOption}
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={['constraintDefDisplayName']}
                onFieldChange={onConstraintDefChange}
            />
        </div>

    const memoSymbol =
        <div className='im-flex-row-item' style={{ width: "9vw"}}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentConstraintEvaluator || ''}
                fieldValue={currentConstraintEvaluator}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'constraintEvaluator'}
                options={currentConstraintDef?.constraintDefLovQuery ? comboBoxEvaluatorForStringOption : comboBoxEvaluatorOption}
                maxLength={60}
                isShowMissingError={true}
                requiredFieldList={['constraintEvaluator']}
                onFieldChange={onConstraintEvaluatorChange}
            />
        </div>

    const memoValue =
        <div className='im-flex-row-item' style={{ width: "19.5vw" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentConstraintDef?.constraintDefLovQuery ? constraintValues?.map((item) => item?.constraintValue).join(', ') ?? '' 
                    : constraintValues[0]?.constraintValue ?? ''}
                fieldValue={currentConstraintDef?.constraintDefLovQuery ? constraintValues?.map((item) => item?.constraintValue) 
                    : constraintValues[0]?.constraintValue}
                isSaveClicked={isSaveClicked}
                allValues={constraintValues?.map((item) => item?.constraintValue).join(', ') ?? ''}
                fieldType={currentConstraintDef?.constraintDefLovQuery ? FieldType.DROPDOWN : FieldType.TEXT}
                fieldKey={'constraintValue'}
                options={currentConstraintDef?.constraintDefLovQuery ? comboBoxValueOption : []}
                maxLength={60}
                isShowMissingError={true}
                isMultipleDropDown={isMultipleValue}
                requiredFieldList={['constraintValue']}
                onFieldChange={onConstraintValuesChange}
            />
        </div>

    const memoDeleteIcon =
        <div className='im-flex-row-item' style={{ width: "30px"}}>
            <IconButton fileName="Icon-trash" disabled={isRead} size="medium" onClick={() => onDeleteConstraintItem(constraintItem)} />
        </div>

    useEffect(() => {
        setCurrentConstraintDef(constraintItem.constraintDef);
        setCurrentConstraintEvaluator(constraintItem.constraintEvaluator);
        if(constraintItem.constraintEvaluator === "IN" || constraintItem.constraintEvaluator === "NOT_IN"){
            setIsMultipleValue(true);
        }else{
            setIsMultipleValue(false);
        }
        setConstraintValues(constraintItem.constraintValues);
    }, [constraintItem, criteriaVM]);

    return (
        <CriteriaItemContainer>
            {memoCriteria}
            {memoSymbol}
            {memoValue}
            {memoDeleteIcon}
        </CriteriaItemContainer>
    );
};

export default memo(ConstraintItemPanel);