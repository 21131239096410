import { ConstraintEntity, EMPTY_CRITERIA_CONS_ENTITY } from "domain/entity/Criteria/ConstraintEntity";
import { CriteriaConstant } from "presentation/constant/Criteria/CriteriaConstant";
import { CriteriaModel } from "presentation/model/Criteria/CriteriaModel";
import { useCriteriaTracked } from "presentation/store/Criteria/CriteriaProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { memo, useEffect, useState } from "react";
import { IconButton } from "veronica-ui-component/dist/component/core";
import ConstraintItemPanel from "./ConstraintItemPanel";

const ConstraintGroupPanel = ({ criteriaState }: { criteriaState: CriteriaModel }) => {
    const [, setCriteriaState] = useCriteriaTracked();
    const { constraints } = criteriaState.componentState.currentSelectItem;
    const CRITERIA_CONSTANT = CriteriaConstant.Table;
    const [refresh, setRefresh] = useState<boolean>(false);

    const onAddConstraintItem = () => {
        setCriteriaState({
            ...criteriaState,
            componentState: {
                ...criteriaState.componentState,
                currentSelectItem: {
                    ...criteriaState.componentState.currentSelectItem,
                    constraints: [...constraints, { ...EMPTY_CRITERIA_CONS_ENTITY }]
                },
                isSliderOpen: true
            }
        });
        setRefresh(!refresh);
    }

    const onDeleteConstraintItem = (removeItem: ConstraintEntity) => {
        const newItems = constraints.filter((item) => item.index !== removeItem.index);
        const updatedItems = newItems.map((item, index) => ({
            ...item,
            index: index
        }));

        setCriteriaState({
            ...criteriaState,
            componentState: {
                ...criteriaState.componentState,
                currentSelectItem: {
                    ...criteriaState.componentState.currentSelectItem,
                    constraints: updatedItems
                }
            }
        });
        setRefresh(!refresh);
    }

    const onUpdateConstraintItem = (updateItem: ConstraintEntity) => {
        const newConstraints = criteriaState.componentState.currentSelectItem.constraints.map((item, index) => {
            if (index === updateItem.index) {
                return updateItem;
            }
            return item;
        });

        setCriteriaState({
            ...criteriaState,
            componentState: {
                ...criteriaState.componentState,
                currentSelectItem: {
                    ...criteriaState.componentState.currentSelectItem,
                    constraints: newConstraints
                }
            }
        });
        setRefresh(!refresh);
    }

    useEffect(() => {
        const updatedConstraints = constraints?.map((item, index) => ({
            ...item,
            index: index
        }));
        if (updatedConstraints) {
            setCriteriaState({
                ...criteriaState,
                componentState: {
                    ...criteriaState.componentState,
                    currentSelectItem: {
                        ...criteriaState.componentState.currentSelectItem,
                        constraints: updatedConstraints
                    }
                }
            });
        }
    }, [constraints, criteriaState, setCriteriaState]);

    return (
        <>
            <CriteriaItemContainer>
                <span className='parent-label'>{CRITERIA_CONSTANT.CRITERIA}</span>
                <div className='password-eye constraint-def-add'>
                    <IconButton fileName="Icon-add-row" disabled={criteriaState.componentState.isRead} size="medium" onClick={onAddConstraintItem} toolTipText="Add"></IconButton></div>
            </CriteriaItemContainer>
            <>
                {constraints && constraints.map((constraintItem, index) => {
                    constraintItem = { ...constraintItem, index: index };
                    return (
                        <CriteriaItemContainer>
                            <ConstraintItemPanel
                                key={index}
                                constraintItem={constraintItem}
                                criteriaState={criteriaState}
                                onDeleteConstraintItem={onDeleteConstraintItem}
                                onUpdateConstraintItem={onUpdateConstraintItem}
                            />
                        </CriteriaItemContainer>
                    );
                })}
            </>
        </>
    );
}

export default memo(ConstraintGroupPanel);