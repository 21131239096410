import { CriteriaEntity } from "domain/entity/Criteria/CriteriaEntity";
import { CriteriaConstant } from "presentation/constant/Criteria/CriteriaConstant";
import { useCriteriaVM } from "presentation/hook/Criteria/useCriteriaVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCriteriaTracked } from "presentation/store/Criteria/CriteriaProvider";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo, useCallback, useState } from "react";
import { HPHBreadcrumb, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const CriteriaEditPanelTitleBar: React.FC = () => {
    const [criteriaState] = useCriteriaTracked();
    const criteriaVM = useCriteriaVM();
    const messageBarVM = useMessageBarVM();
    const {currentSelectedRow, masterState} = criteriaState;
    const {editingCriteriaEntity, isAdd, isEditable, isRead} = masterState;
    
    const [anainfoState] = useANAInfoTracked();
    const {allowCreate, allowUpdate} = anainfoState;
    const [isLoading, setIsLoading] = useState(false);
    const currentEntity = isRead? currentSelectedRow : editingCriteriaEntity;

    const handleReset = useCallback(async () => {
        criteriaVM.onResetClick();
    }, [criteriaVM]);

    const handleEdit = useCallback(()=>{
        criteriaVM.onEdit(currentEntity);
    },[currentEntity, criteriaVM])

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        try {
            let isSave = true;
            if(currentEntity.id === null && criteriaState.criteriaEntityList?.some(item => item.criteriaName === currentEntity.criteriaName)){
                isSave = false;
            }
            if(currentEntity.id !== null){
                const criteria = criteriaState.criteriaEntityList?.filter(item => item.criteriaName === currentEntity.criteriaName);
                if(criteria?.some(item => item.id !== currentEntity.id)){
                    isSave = false;
                }
            }

            if(isSave){
                criteriaVM.onSaveClicked();
                let criteria:CriteriaEntity = {
                    ...currentEntity,
                    criteriaType:criteriaState.entrypoint
                };
                const res = await criteriaVM.onSave(criteria,isAdd,criteriaState.componentState.consGrpList);
                if(res && res["mandatoryCheckFail"]){
                    messageBarVM.showError(res["mandatoryCheckFail"]?.toString());
                }else{
                    messageBarVM.showSuccess('Save Successfully!');
                }
            }else{
                messageBarVM.showError('"'+currentEntity.criteriaName + '" already exists. Pls rename it.');
            }
        } catch (error) {

        }finally{
            setIsLoading(false)
        }
        
    },[currentEntity, isAdd, messageBarVM, criteriaVM, criteriaState.componentState.consGrpList, criteriaState.entrypoint, criteriaState.criteriaEntityList]);

    let title: string= "";
    if(criteriaState.entrypoint==='CHARGE'){
        title = CriteriaConstant.Title.CHARGE_CRITERIA_MAIN;
    }else if(criteriaState.entrypoint==='GCGEN'){
        title = CriteriaConstant.Title.GENERAL_CHARGE_CRITERIA_MAIN;
    }else if(criteriaState.entrypoint==='CHGEXL'){
        title = CriteriaConstant.Title.CHARGE_EXCLUSION_CRITERIA_MAIN;
    }else if(criteriaState.entrypoint==='DOCSEP'){
        title = CriteriaConstant.Title.DOCUMENT_SEPARATION_CRITERIA_MAIN;
    }else if(criteriaState.entrypoint==='DOCGROUP'){
        title = CriteriaConstant.Title.DOCUMENT_GROUPING_CRITERIA_MAIN;
    }else{
        title = CriteriaConstant.Title.CHARGE_CRITERIA_MAIN;
    }
    

    return <Sidebarheader style={{width: '100%'}}>
        {isLoading && <Loader Indicator="Spinner" size="Medium" />}
        <HeaderWithBackButton callback={(criteriaVM.onCloseClick)} isShowBackButton={true}>
            <Breadcrumb>
                <HPHBreadcrumb breadcrumbData={[{title: CriteriaConstant.Charge.CHARGE},{ title: title }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
            </Breadcrumb>
        </HeaderWithBackButton>
        <StyledAction>
            {(!isAdd && (isEditable && isRead)) &&
                <>
                    {/* Edit Button */}
                    {
                        (
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                    }
                </>}
            {(isAdd || (isEditable && !isRead)) &&
                <>
                    {/* Reset Form Button */}
                    <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                    {
                        (
                            (isAdd && allowCreate) ||
                            (isEditable && allowUpdate)
                        )
                        && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                    }
                </>}
        </StyledAction>
    </Sidebarheader>
}

export default memo(CriteriaEditPanelTitleBar);