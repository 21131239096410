import { SelectionChangedEvent } from "ag-grid-community";
import { CriteriaEntity } from "domain/entity/Criteria/CriteriaEntity";
import { CriteriaRequestEntity } from "domain/entity/Criteria/CriteriaRequestEntity";
import _ from "lodash";
import { INITIAL_CHARGE_CRITERIA_COL_DEF } from "presentation/constant/Criteria/ChargeCriteriaColumnDefinition";
import { INITIAL_CHARGE_EXCL_CRITERIA_COL_DEF } from "presentation/constant/Criteria/ChargeExclusionCriteriaColumnDefinition";
import { INITIAL_GENERAL_CHARGE_CRITERIA_COL_DEF } from "presentation/constant/Criteria/GeneralChargeCriteriaColumnDefinition";
import { useCriteriaVM } from "presentation/hook/Criteria/useCriteriaVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCriteriaTracked } from "presentation/store/Criteria/CriteriaProvider";
import { changeCursor } from "presentation/view/components/NbisRightClickMenu/RightClickMenuAssist";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";


const CriteriaTablePanel: React.FC = () => {
    const [criteriaState] = useCriteriaTracked();
    const criteriaVM = useCriteriaVM();
    const gridRef: any = useRef(null);
    const rightClickRef: any = createRef();
    const { criteriaEntityList, selectedRows, subMenuItemArray, entrypoint } = criteriaState;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const messageBarVM = useMessageBarVM();
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<CriteriaEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const getCurrentColDefs = useCallback(() => {
        let colDefs;
        if (entrypoint === 'CHARGE') {
            colDefs = INITIAL_CHARGE_CRITERIA_COL_DEF;
        } else if (entrypoint === 'GCGEN') {
            colDefs = INITIAL_GENERAL_CHARGE_CRITERIA_COL_DEF;
        } else if (entrypoint === 'CHGEXL' || entrypoint === 'DOCSEP' || entrypoint === 'DOCGROUP') {
            colDefs = INITIAL_CHARGE_EXCL_CRITERIA_COL_DEF;
        } else {
            colDefs = INITIAL_CHARGE_CRITERIA_COL_DEF;
        }
        return colDefs;
    }, [entrypoint]);

    /*const getCurrentData = useCallback(() => {
        let data;
        if (criteriaEntityList && criteriaEntityList.length > 0) {
            if (entrypoint === 'CHARGE') {
                data = chargeCriteriaTransferRowData(criteriaEntityList ?? []);
            } else if (entrypoint === 'GCGEN') {
                data = generalChargeCriteriaTransferRowData(criteriaEntityList ?? []);
            } else if (entrypoint === 'CHGEXL' || entrypoint === 'DOCSEP' || entrypoint === 'DOCGROUP') {
                data = chargeExclCriteriaTransferRowData(criteriaEntityList ?? []);
            } else {
                data = chargeCriteriaTransferRowData(criteriaEntityList ?? []);
            }
        }
        return data;
    }, [criteriaEntityList, entrypoint])*/

    useEffect(() => {
        if (!selectedRows || selectedRows.length <= 0) {
            gridRef.current?.gridRef.current.api?.setColumnDefs(getCurrentColDefs());
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
        changeCursor(`criteria-table-${entrypoint}`, getShowMoveCursor());
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();

        criteriaVM.updateSelectedRows(selectedRows);
    }, [criteriaVM])

    const handleAdd = useCallback(() => {
        criteriaVM.onAddClick();
    }, [criteriaVM]);

    const getShowMoveCursor = useCallback(() => {
        return showMoveCursor;
    }, [showMoveCursor]);

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef?.current?.gridRef.current.api?.deselectAll();
        changeCursor(`criteria-table-${entrypoint}`, getShowMoveCursor());
    }, [onTableSelectionClicked, entrypoint, getShowMoveCursor])

    const handleRowDoubleClick = useCallback((entity: CriteriaEntity) => {
        criteriaVM.onRowDoubleClick(entity);
    }, [criteriaVM])

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [messageBarVM, rightClickRef, allowUpdate, selectedRows])



    const getSelectedRows = useCallback(() => {
        return selectedRows;
    }, [selectedRows]);

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;
        let criterRequestEntity: CriteriaRequestEntity = {};
        criterRequestEntity = { criteriaList: newAllRows };

        setIsLoading(true);

        criteriaVM.onApply(criterRequestEntity).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showWarining('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                criteriaVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                criteriaVM.searchCriteria(entrypoint).then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    criteriaVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    criteriaVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [messageBarVM, criteriaVM]);

    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        criteriaVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [criteriaVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(criteriaState.criteriaEntityList);
        criteriaVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [criteriaState.criteriaEntityList, criteriaVM]);

    const memoRightClickMenu = useMemo(() => {
        return (allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
            rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
            onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
            showConfirmMove={showMoveCursor} priorityName="priority" />;
    }, [allowUpdate, getAllRows, getSelectedRows, onMoveCancel, onRefreshRow, onSaveMove, showMoveCursor]);

    const memoCriteriaTable = useMemo(() => {

        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
                    <HPHTable
                        id={`criteria-table-${entrypoint}`}
                        key={`criteria-table-${entrypoint}`}
                        isNewColumnSetting={true}
                        columns={getCurrentColDefs()}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={true}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={false}
                        //rowSelection={"multiple"}
                        isRowHighligted={true}
                        onAddClick={handleAdd}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: CriteriaEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 75px)"
                        ref={gridRef}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                        onBodyScroll={(e: any) => changeCursor(`criteria-table-${entrypoint}`, getShowMoveCursor())}

                    />
                </div>
            </>
        );
    }, [allRows, allowUpdate, getAllRows, getSelectedRows, getShowMoveCursor, handleRowDoubleClick, handleSelectionChange, onMoveCancel, onRefreshRow, onSaveMove, rightClickRef, showMoveCursor])

    useEffect(() => {
        if (initialAllRows && criteriaState.criteriaEntityList && !_.isEmpty(criteriaState.criteriaEntityList)) {
            setAllRows(criteriaState.criteriaEntityList.map((criteriaEntity, index) => ({
                ...criteriaEntity,
                index: index || 0
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, criteriaState.criteriaEntityList]);

    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoCriteriaTable}
    </TableWrapper>
    </>;
}

export default memo(CriteriaTablePanel);
